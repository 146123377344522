import { defineComponent, ref } from 'vue';
import Datepicker from 'vue3-datepicker';
import { nl } from 'date-fns/locale';
import CustomerSearchField from '@/components/Customers/CustomerSearchField';
import { cloneDeep } from 'lodash';
import reservationFilterValid from '@/validators/reservationFilterValid';
import useVuelidate from '@vuelidate/core';
export default defineComponent({
    name: 'ReservationFilter',
    components: {
        Datepicker,
        CustomerSearchField
    },
    setup() {
        const filter = {
            field: null,
            value: '',
            fromDate: null,
            toDate: null,
            sorted: 'fromDate'
        };
        const localFilter = ref(filter);
        const rules = {
            localFilter: { reservationFilterValid }
        };
        return { localFilter, validate: useVuelidate(rules, { localFilter }) };
    },
    data: () => ({
        locale: nl,
        filterAcceptedOnly: false,
        filterArrivedOnly: false,
        filterCustomerId: ''
    }),
    computed: {
        reservationFilter() {
            return this.$store.direct.state.reservations.filter;
        }
    },
    watch: {
        reservationFilter: {
            handler(filter) {
                this.localFilter = cloneDeep(filter);
            },
            immediate: true
        },
        localFilter: {
            handler: function (newVal, oldVal) {
                if ((newVal !== undefined && oldVal !== undefined) &&
                    (newVal.value !== oldVal.value ||
                        newVal.fromDate !== oldVal.fromDate ||
                        newVal.toDate !== oldVal.toDate)) {
                    //this.updateFilter() // TODO automatic filtering
                }
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
        updateFilter() {
            if (this.filterCustomerId !== '') {
                this.localFilter.field = 'uid';
                this.localFilter.value = this.filterCustomerId;
            }
            else if (this.filterAcceptedOnly) {
                this.localFilter.field = 'accepted';
                this.localFilter.value = 'false';
            }
            else if (this.filterArrivedOnly) {
                this.localFilter.field = 'arrived';
                this.localFilter.value = 'false';
            }
            else {
                this.localFilter.field = null;
                this.localFilter.value = '';
                this.localFilter.sorted = 'fromDate';
            }
            this.$store.direct.dispatch.reservations.applyFilter(this.localFilter);
        }
    }
});
