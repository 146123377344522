import { defineComponent } from 'vue';
import ReservationOverviewTable from '@/components/Reservation/ReservationOverviewTable';
import ReservationFilter from '@/components/Reservation/ReservationFilter';
export default defineComponent({
    name: 'ReservationOverview',
    components: {
        ReservationOverviewTable,
        ReservationFilter
    },
    computed: {
        isSuperUser() {
            return this.$store.direct.getters.auth.isSuperUser;
        }
    }
});
